<template>
  <div ref="container" class="memberContainer">
    <div class="title">入会申请</div>
    <div class="tableContainer">
      <div class="addWrap">
        <el-button class="add" @click.stop="down">下载表格</el-button>
      </div>
      <el-row>
        <el-col class="table">
          <el-table
            :data="tableData"
            border
            header-cell-class-name="headerClass"
            cell-class-name="cellClass"
            style="width: 100%"
          >
            <el-table-column
              label="单位名称"
              min-width="200"
              prop="enterpriseName"
              align="center"
            />
            <el-table-column
              label="创建时间"
              min-width="190"
              prop="createTime"
              align="center"
            />
            <el-table-column
              label="编辑"
              prop="demand"
              min-width="140"
              align="center"
            >
              <template slot-scope="scope">
                <!-- <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button> -->
                <span class="deleteCommon" @click.stop="handShow(scope.row)"
                  >查看详情</span
                >
                <el-dialog :visible.sync="dialogVisible" title="入会详情">
                  <div class="detailTitle">Q1:单位名称</div>
                  <div class="detailContent">{{ tableRow.enterpriseName }}</div>
                  <div class="detailTitle">Q2:通讯地址</div>
                  <div class="detailContent">{{ tableRow.address }}</div>
                  <div class="detailTitle">Q3:法人代表或负责人信息</div>
                  <div class="detailContent">姓名:{{ tableRow.name }}</div>
                  <div class="detailContent">手机:{{ tableRow.mobile }}</div>
                  <div class="detailContent">
                    公司职务:{{ tableRow.position }}
                  </div>
                  <div class="detailContent">
                    固定电话:{{ tableRow.telephone || "无" }}
                  </div>
                  <div class="detailContent">
                    邮箱:{{ tableRow.email || "无" }}
                  </div>
                  <div class="detailContent">
                    传真:{{ tableRow.fax || "无" }}
                  </div>
                  <div class="detailTitle">Q4:对接联系人信息</div>
                  <div class="detailContent">
                    姓名:{{ tableRow.contactName }}
                  </div>
                  <div class="detailContent">
                    手机:{{ tableRow.contactMobile }}
                  </div>
                  <div class="detailContent">
                    公司职务:{{ tableRow.contactPosition }}
                  </div>
                  <div class="detailContent">
                    固定电话:{{ tableRow.contactTelephone || "无" }}
                  </div>
                  <div class="detailContent">
                    邮箱:{{ tableRow.contactEmail || "无" }}
                  </div>
                  <div class="detailContent">
                    传真:{{ tableRow.contactFax || "无" }}
                  </div>
                  <div class="detailTitle">Q5:单位性质</div>
                  <div class="detailContent">
                    {{
                      tableRow.unitNature > -1 &&
                      unitNatureArr[tableRow.unitNature].name
                    }}
                  </div>
                  <div class="detailTitle">Q6:入会职务</div>
                  <div class="detailContent">
                    {{
                      tableRow.memberPosition > -1 &&
                      memberPositionArr[tableRow.memberPosition].name
                    }}
                  </div>
                  <div class="detailTitle">Q7:单位简介</div>
                  <div class="detailContent">{{ tableRow.enterpriseDes }}</div>
                </el-dialog>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <div class="pagination" style="text-align: center">
        <el-pagination
          :total="demandListDetail.total"
          :page-size="10"
          layout="prev, pager, next"
          @current-change="clickPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getApplyMemberList } from "@/apis/user";
import { parseTime } from "@/utils/index";
import { getCookie } from "@/utils/auth";

export default {
  data() {
    return {
      // tableHeight: '',
      tableData: [],
      current: 1,
      demandListDetail: "",
      unitNatureArr: [
        { id: 1, name: "产业园管理机构" },
        { id: 2, name: "商业服务机构" },
        { id: 3, name: "企业" },
        { id: 4, name: "其他" },
      ],
      memberPositionArr: [
        { id: 1, name: "会长单位 (200000元/年)" },
        { id: 2, name: "常务副会长单位 (100000元/年)" },
        { id: 3, name: "副会长单位 (50000元/年)" },
        { id: 4, name: " 理事单位 (30000元/年)" },
        { id: 5, name: " 会员单位 (10000元/年) (30000元/年)" },
      ],
      dialogVisible: false,
      tableRow: "",
    };
  },
  activated() {
    // this.tableHeight = this.$refs.container.offsetHeight - 240
    this.getApplyMemberList(this.current);
  },

  methods: {
    getApplyMemberList(current = 1) {
      getApplyMemberList({
        current,
        programId: this.$route.params.programId,
      }).then((res) => {
        var records = res.result.records;
        records.forEach((item) => {
          item.createTime = parseTime(item.createTime);
        });
        this.tableData = records;
        this.demandListDetail = res.result;
      });
    },
    handShow(row) {
      this.dialogVisible = true;
      this.tableRow = row;
    },
    // 页码切换
    clickPage(e) {
      this.current = e;
      this.getApplyMemberList(e);
    },
    down() {
      var token = getCookie("Auth");
      window.location.href = `https://api.joinzs.com/mpgenerator/gov/downloadApplyMember4Csv?auth=${token}`;
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/mixin";

.memberContainer {
  .el-dialog {
    border-radius: 14px;
  }
  .el-dialog .el-dialog__body {
    overflow-y: scroll;
    max-height: calc(80vh - 54px - 70px);
  }
}
</style>

<style lang="scss" scoped>
@import "../../styles/mixin";
.memberContainer {
  width: 1200px;
  margin: 0 auto;
  padding: 40px 0 40px 0;
  .title {
    letter-spacing: 2px;
    font-size: 22px;
    line-height: 1;
    color: #333;
    font-weight: bold;
    padding-left: 30px;
  }
  .tableContainer {
    overflow: hidden;
    border: solid 1px #d2d2d2;
    margin: 40px 34px 0;
    box-sizing: border-box;
    .addWrap {
      overflow: hidden;
    }
    .add {
      float: left;
      margin: 12px 0 0 22px;
      @include btnCardConfirm(100px, 34px, 14px);
    }
    .table {
      margin-top: 12px;
    }
  }
  .pagination {
    margin-top: 24px;
  }
  .el-dialog__body {
    padding: 15px 20px 15px;
  }
  .detailTitle {
    text-align: left;
    font-size: 16px;
    color: #000;
    padding: 8px 20px;
  }
  .detailContent {
    text-align: left;
    font-size: 14px;
    columns: #333;
    padding: 0 46px;
  }
}
</style>
